<template>
  <div class="p-grid">
    <div class="p-col-12">
      <DataTable
          ref="reportEngineerTable"
          :value="detailsData"
          :paginator="true"
          class="p-datatable-customers p-datatable-striped"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          :autoLayout="true"
          :loading="isLoading"
          v-model:filters="filters"
          selectionMode="single"
          stateStorage="session"
          stateKey="selected-detail-report-state"
          @row-click="goToTask"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-flex-column">
            <div class="table-header p-col-12 p-mb-4">
              {{ tableTitle }}
            </div>

            <div class="p-d-flex p-flex-row p-jc-between">
              <div class="filters p-d-flex p-flex-row">
                <MultiSelect
                    id="engineer"
                    v-model="selectedEngineers"
                    :options="allEngineers"
                    placeholder="Сотрудники"
                    optionLabel="nameText"
                    optionValue="id"
                    emptyFilterMessage="Совпадений не найдено"
                    class="multiselect p-mr-4"
                    :filter="true"
                />
                <RangeDatePicker v-model:range="filterDate"/>
              </div>

              <div class="buttons">
                <Button
                    @click="loadDetails"
                    class="p-button-success p-mr-2"
                >
                  Сформировать отчет
                </Button>
                <Button
                    @click="downloadReport"
                    class="p-button-success p-mr-2"
                    :disabled="!updatedUrl"
                >
                  Скачать отчет
                </Button>
                <Button class="p-button-danger" @click="clearFilters">
                  Очистить фильтры
                </Button>
              </div>
            </div>
          </div>
        </template>
        <template #empty>Запчасти не найдены</template>
        <template #loading>Загружаем запчасти...</template>
        <Column
            field="lastName"
            header="Инженер"
            :sortable="true"
            :show-filter-menu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.lastName }}
          </template>
        </Column>
        <Column
            field="taskId"
            header="Номер заявки"
            :sortable="true"
            :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.taskId }}
          </template>
        </Column>

        <Column
            field="name"
            header="Название"
            :sortable="true"
            :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>
        </Column>

        <Column
            field="docId"
            header="ID документа"
            :sortable="true"
            :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.docId }}
          </template>
        </Column>

        <Column
            field="article"
            header="Артикул"
            :sortable="true"
            :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.article }}
          </template>
        </Column>

        <Column
            field="allCount"
            header="Запчастей получено"
            :sortable="true"
            :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.allCount }}
          </template>
        </Column>

        <Column
            field="useCount"
            header="Запчастей использовано"
            :sortable="true"
            :show-filter-menu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.useCount }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import {environment} from "@/config";
import {Calendar, DatePicker} from "v-calendar";
import RangeDatePicker from "../../operator/components/RangeDatePicker";
import {mapGetters} from "vuex";

export default {
  components: {
    RangeDatePicker,
    Calendar,
    DatePicker,
  },
  props: ["id", "dateStart", "dateEnd", "workTypes"],
  name: "SelectedEngineerReport",
  data() {
    return {
      filterDate: {
        start: new Date(),
        end: new Date(),
      },
      detailsData: null,
      isLoading: false,
      selectedEngineers: null,
      tableTitle: "Отчёт по запчастям",
      updatedUrl: null,
    };
  },
  async mounted() {
    let savedFilterData = JSON.parse(
        window.sessionStorage.getItem("engineerDetails")
    );

    if (savedFilterData) {
      this.selectedEngineers = savedFilterData.engineersId;
      this.filterDate = {
        start: new Date(savedFilterData.dateStart),
        end: new Date(savedFilterData.dateEnd)
      };
      await this.loadDetails();
    }

  },
  computed: {
    ...mapGetters({
      allEngineers: "myprofiles/engineers",
    }),
  },
  watch: {},
  methods: {
    goToTask(event) {
      this.$router.push(`/task/${event.data.taskId}`);
    },

    async loadDetails() {
      this.isLoading = true;

      try {
        this.filterDate.start.setHours(0, 0, 0, 0);
        this.filterDate.end.setHours(23, 59, 59, 0);

        this.updatedUrl =
            `?engineerIds=${this.selectedEngineers}&dateStart=${this.filterDate.start.toISOString()}` +
            `&dateEnd=${this.filterDate.end.toISOString()}`;

        await this.$router.replace(this.updatedUrl);

        const response = await fetch(
            `${environment.apiUrl}/details/unused${this.updatedUrl}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
                "Content-Type": "application/json",
              }
            }
        );
        const data = await response.json();
        this.detailsData = Object.keys(data).reduce((acc, key) => {
          const items = data[key].map(item => ({...item, lastName: key}));
          return [...acc, ...items];
        }, []);

        let detailsFilter = {
          engineersId: this.selectedEngineers,
          dateStart: this.filterDate.start.toISOString(),
          dateEnd: this.filterDate.end.toISOString(),
        }

        window.sessionStorage.setItem(
            "engineerDetails",
            JSON.stringify(detailsFilter)
        );

      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },

    async downloadReport() {
      this.isLoading = true;

      try {
        const response = await fetch(
            `${environment.apiUrl}/reports/unusedDetails/excel/${this.updatedUrl}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
              },
            }
        );
        const url = window.URL.createObjectURL(await response.blob());
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Отчёт о неиспользованных запчастях.xls`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },

    clearFilters() {
      this.selectedEngineers = [];
      this.filterDate = {
        start: new Date(),
        end: new Date(),
      };
    },
  },
};
</script>

<style scoped>
.multiselect {
  width: 190px;
  color: black;
}
</style>
